import React, { FC } from 'react';

import Innedit from '~/images/innedit-typo.svg';
import Tourdepiste from '~/images/logo-tourdepiste.svg';

const SplashScreen: FC<{ pathname: string }> = function () {
  const isTourdepiste =
    'Tourdepiste' === process.env.GATSBY_CONFIG_MANIFEST_NAME;

  return (
    <div className="flex flex-col space-y-3 h-screen w-screen items-center justify-center">
      <img
        alt="Tourdepiste"
        src={isTourdepiste ? Tourdepiste : Innedit}
        width={150}
      />
    </div>
  );
};

export default SplashScreen;
